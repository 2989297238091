@import '../../styles/contants.scss';

.wrapper{
    background: white;
    overflow: hidden;

    .container{
        flex-direction: column;
        gap: 2rem;

        .heading{
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;
            flex-wrap: wrap;

            >:nth-child(2){
                text-decoration: underline;
                color: orange;
                cursor: pointer;
            }
        }

        .showCase{
            width: 100%;
            gap: 1.5rem;
            justify-content: space-around;
            flex-wrap: wrap;

            >img{
                @media (max-width: $sm) {
                    width: 100%;
                }
                border-radius: 20px;
                max-width: 25rem;
                max-height: 18rem;
                box-shadow: 0px 21px 13px rgba(0,0,0,13%);
                cursor: pointer;
            }
        }
    }
}