@import './contants.scss';

.bg-primary {
  background: #F8F7F1;
}

.innerWidth {
  @media (min-width: $xxl) {
    max-width: 1280px;
    margin: auto;
  }

  width: 100%;
}

.paddings {
  @media (min-width: $sm) {
    padding: 4rem;
  }


  @media (min-width: $xl),
  (min-width: $md) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}



.xPaddings {
  @media (min-width: $sm) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  padding-left: 1.5rem;
  /* 24px */
  padding-right: 1.5rem;
  /* 24px */
}

.yPaddings {
  @media (min-width: $sm) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  @media (min-width: $xl) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  padding-top: 3rem;
  padding-bottom: 3rem;
}

.topPaddings {
  @media (min-width : $sm) {
    padding-top: 4rem;
  }

  @media (min-width: $xl) {
    padding-top: 2rem;
  }

  padding-top: 3rem;
}


.bottomPaddings {
  @media (min-width : $sm) {
    padding-bottom: 4rem;
  }

  @media (min-width: $xl) {
    padding-bottom: 2rem;
  }

  padding-bottom: 3rem;
}


.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexStart {
  display: flex;
  justify-content: start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-items: end;
}

.primaryText {
  @media (max-width: $sm) {
    font-size: 2.5rem;
  }
  font-size: 3rem;
  font-weight: bold;
}

.secondaryText {
  @media (max-width: $sm) {
    font-size: 1.1rem;
    line-height: 40px;
  }
  line-height: 50px;
  font-size: 1.3rem;
  font-weight: 500
}
.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}